import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { SmartLink } from './SmartLink';

export const MenuLink = ({ link, style, dataLayerClickEvent }) => {
  const additionalProps = {};
  const parentAdditionalProps = {};
  if (link.dataLayerEvent) {
    additionalProps.dataLayerClickEvent = {
      ...dataLayerClickEvent,
      ...{ event: link.dataLayerEvent }
    };
  } else if (dataLayerClickEvent) {
    additionalProps.dataLayerClickEvent = dataLayerClickEvent;
  }
  if (link.containerClass) {
    parentAdditionalProps.className = link.containerClass;
  }
  if (link.class) {
    additionalProps.className = link.class;
  }
  if (link.hideOnMobile === '1') {
    parentAdditionalProps.className = `${
      parentAdditionalProps.className
        ? `${parentAdditionalProps.className} `
        : ''
    }hidden-on-mobile`;
  }
  if (link.hideOnTablet === '1') {
    parentAdditionalProps.className = `${
      parentAdditionalProps.className
        ? `${parentAdditionalProps.className} `
        : ''
    }hidden-on-tablet`;
  }
  return (
    <li
      css={css`
        ${style}
      `}
      {...parentAdditionalProps}
    >
      <SmartLink to={link.url.path} {...additionalProps}>
        {link.title}
      </SmartLink>
    </li>
  );
};

MenuLink.propTypes = {
  link: PropTypes.objectOf(PropTypes.any).isRequired,
  style: PropTypes.any,
  dataLayerClickEvent: PropTypes.objectOf(PropTypes.any)
};
MenuLink.defaultProps = {
  style: undefined,
  dataLayerClickEvent: {
    event: undefined,
    location: 'Any'
  }
};
